import React from 'react';

import NewsPage from './../components/organisms/WordPress/NewsPage';
import Layout from './../components/organisms/WordPress/Layout';

export default function event() {

  return (
    <>
      <Layout >
        <NewsPage />
      </Layout>
    </>
  )
}
